<template>
  <div>
    <p class="text-2xl mb-6">Historique des changements de paliers</p>
    <Filters :dev-filter-visible="devFilterVisible"></Filters>
    <CwvThresholdChangesTable
      :dev-filter-visible="devFilterVisible"
    ></CwvThresholdChangesTable>
  </div>
</template>

<script>
import { Konami, KonamiManager } from "@/utils/konami";
import { mdiPlusBox } from "@mdi/js";
import Filters from "@/components/web-perf/cwv/filters/Filters.vue";
import CwvThresholdChangesTable from "@/components/web-perf/cwv/cwv-threshold-changes/tables/CwvThresholdChangesTable.vue";

export default {
  name: "ProjectsList",
  components: {
    Filters,
    CwvThresholdChangesTable,
  },
  created() {
    this.$store.commit("appConfig/UPDATE_APP_CONTENT_WIDTH", "largeWidth");
    this.konamiManager = new KonamiManager(this, [
      new Konami([68, 69, 86], () => {
        this.devFilterVisible = !this.devFilterVisible;
      }),
    ]);
  },
  onDestroy() {
    this.$store.commit("appConfig/UPDATE_APP_CONTENT_WIDTH", "boxed");
  },
  data() {
    return {
      devFilterVisible: false,
      icons: {
        mdiPlusBox,
      },
    };
  },
  methods: {},
  watch: {},
};
</script>
<style scoped></style>
