import { render, staticRenderFns } from "./CwvThresholdChanges.vue?vue&type=template&id=48edaacc&scoped=true&"
import script from "./CwvThresholdChanges.vue?vue&type=script&lang=js&"
export * from "./CwvThresholdChanges.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48edaacc",
  null
  
)

export default component.exports