<template>
  <v-card>
    <v-card-text>
      <v-data-table
        locale="fr-FR"
        :page="options.page"
        :pageCount="numberOfPages"
        :headers="visibleHeaders"
        :items="items"
        :sort-by="'updated'"
        :sort-desc="true"
        :options.sync="options"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100],
        }"
        :items-per-page="options.itemsPerPage"
      >
        <template v-slot:item.change="{ item }">
          <div style="display: flex; align-items: center; width: 100%">
            <span
              style="flex: 1"
              :class="getColorByRange(item.metric, item.yesterdayValue)"
              >{{ formatCwvMetric(item.yesterdayValue, item.metric) }}</span
            >
            <v-icon left size="22" style="flex: 1">
              {{ icons.mdiArrowRight }}
            </v-icon>
            <span
              style="flex: 1"
              :class="getColorByRange(item.metric, item.value)"
            >
              {{ formatCwvMetric(item.value, item.metric) }}
            </span>
            <v-icon
              :class="item.evolution === 'Up' ? 'error--text' : 'success--text'"
              left
              size="22"
              style="flex: 1"
            >
              {{
                item.evolution === "Up"
                  ? icons.mdiTrendingDown
                  : icons.mdiTrendingUp
              }}
            </v-icon>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            color="primary"
            :target="`_blank`"
            :to="{
              name: 'cwv-daily-data',
              query: {
                dates: `${yesterdayDate(item.rawDate)},${item.rawDate}`,
                sites: item.site_id,
                pageTypes: item.pageType,
                devices: deviceIds[item.device],
              },
            }"
          >
            <v-icon>
              {{ icons.mdiEarth }}
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<style scoped></style>
<script>
import useAxios from "@/hooks/useAxios";
import { formatCwvMetric } from "@/components/web-perf/cwv/cwv-sites-performance/utils/utils";
import {
  mdiEarth,
  mdiArrowRight,
  mdiTrendingUp,
  mdiTrendingDown,
} from "@mdi/js";
import debounce from "lodash/debounce";
export default {
  name: "CwvThresholdChangesTable",
  props: {
    devFilterVisible: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  data() {
    return {
      search: "",
      numberOfPages: 0,
      items: [],
      deviceMapping: {
        15: "Mobile",
        16: "Desktop",
        17: "Tablette",
      },
      deviceIds: {
        Mobile: 15,
        Desktop: 16,
        Tablette: 17,
      },
      loading: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
      },
      icons: {
        mdiArrowRight,
        mdiTrendingUp,
        mdiTrendingDown,
        mdiEarth,
      },
      ranges: {
        LCP: [2500, 4000],
        INP: [200, 500],
        CLS: [0.1, 0.25],
        FCP: [1800, 3000],
        TTFB: [800, 1800],
      },
      headers: [
        {
          text: "Date",
          value: "date",
          align: "start",
          visible: true,
        },
        {
          text: "Site",
          value: "site_name",
          visible: true,
        },
        {
          text: "Page type",
          value: "pageType",
          visible: true,
        },
        {
          text: "Device",
          value: "device",
          visible: true,
        },
        {
          text: "Métrique",
          value: "metric",
          visible: true,
        },
        {
          text: "Total des Events (j-1)",
          value: "total_count",
          visible: false,
        },
        {
          text: "Total des Posts (j-1)",
          value: "posts_count",
          visible: false,
        },
        {
          text: "Total des Events (j-2)",
          value: "total_count_n1",
          visible: false,
        },
        {
          text: "Total des Posts (j-2)",
          value: "posts_count_n1",
          visible: false,
        },
        {
          text: "Changement",
          value: "change",
          visible: true,
        },
        {
          text: "",
          value: "actions",
          visible: true,
        },
      ],
    };
  },
  methods: {
    formatCwvMetric,
    getColorByRange(metric, value) {
      const range = this.ranges[metric];
      if (value <= range[0]) {
        return "success--text";
      } else if (value <= range[1]) {
        return "warning--text";
      } else {
        return "error--text";
      }
    },
    yesterdayDate: function (inputDate) {
      const date = new Date(inputDate.substring(0, 10));
      date.setDate(date.getDate() - 1);
      return date.toISOString().split("T")[0];
    },
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      if (!this.applyFilters) {
        return;
      }
      const params = {};
      if (this.sites && this.sites.length > 0) {
        params.sites = this.sites.join(",");
      }
      if (this.pageTypes && this.pageTypes.length > 0) {
        params["page_types"] = this.pageTypes.join(",");
      }
      if (this.devices && this.devices.length > 0) {
        params.devices = this.devices.join(",");
      }
      if (this.evolutions && this.evolutions.length > 0) {
        params.evolutions = this.evolutions.join(",");
      }
      if (this.metrics && this.metrics.length > 0) {
        params.metrics = this.metrics.join(",");
      }
      if (this.totalEventsJ1) {
        params["total_events_j1"] = this.totalEventsJ1;
      }
      if (this.totalPostsJ1) {
        params["total_posts_j1"] = this.totalPostsJ1;
      }
      if (this.totalEventsJ2) {
        params["total_events_j2"] = this.totalEventsJ2;
      }
      if (this.totalPostsJ2) {
        params["total_posts_j2"] = this.totalPostsJ2;
      }
      this.loading = true;

      const { data } = await this.axiosGet(
        "/web-perf/cwv-historical-changes",
        params
      );
      this.items = data.items;

      for (const item of this.items) {
        item.rawDate = item.date.substring(0, 10);
        item.date = new Date(item.date).toLocaleDateString("fr-FR");
        item.device = this.deviceMapping[item.device];
        item.evolution = item.status < 0 ? "Up" : "Down";
        item.metric = item.metric.toUpperCase();
      }

      this.loading = false;

      // Force clicking on button again to re-run that request :
      // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
      this.$store.dispatch("common/updateApplyFilters", false);
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters["webPerf/getSites"];
    },
    pageTypes() {
      return this.$store.getters["webPerf/getPageTypes"];
    },
    devices() {
      return this.$store.getters["webPerf/getDevices"];
    },
    evolutions() {
      return this.$store.getters["webPerf/getEvolutions"];
    },
    metrics() {
      return this.$store.getters["webPerf/getMetrics"];
    },
    totalEventsJ1() {
      return this.$store.getters["webPerf/getTotalEvents"];
    },
    totalPostsJ1() {
      return this.$store.getters["webPerf/getTotalPosts"];
    },
    totalEventsJ2() {
      return this.$store.getters["webPerf/getTotalEventsJ2"];
    },
    totalPostsJ2() {
      return this.$store.getters["webPerf/getTotalPostsJ2"];
    },
    visibleHeaders() {
      return this.headers.filter(
        (header) => this.devFilterVisible || header.visible
      );
    },
  },
  watch: {
    applyFilters() {
      this.debouncedGetData();
    },
  },
};
</script>
<style lang="scss" scoped></style>
